import axios from "axios";
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

function Adduser() {
    const navigate = useNavigate();
    const [formvalue, setFormvalue] = useState({username:'', email:''});
    const [message, setMessage] = useState('');
    const handleInput =(e)=>{        
        setFormvalue({...formvalue, [e.target.name]:e.target.value});
    }
    const handleSubmit =async(e)=>{
        e.preventDefault();
        // console.log(formvalue);
        const formData = {username:formvalue.username, email:formvalue.email};
        const res = await axios.post("https://usercrud.imageum.in/api/user.php",formData);

        if(res.data.success){
            setMessage(res.data.success);
            setTimeout( ()=>{
                navigate('/users');
            }, 2000);
            
        }
    }
    return(
        <React.Fragment>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <h3>Adduser</h3>
                        <p className="text-sucess">{ message }</p>
                        <form onSubmit={handleSubmit} style={{textAlign: 'left'}}>
                            <div className="mb-3">
                                <label className="form-label">Username</label>
                                <input type="text" name="username" value={formvalue.username} className="form-control" onChange={handleInput} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email address</label>
                                <input type="email" name="email" value={formvalue.email} className="form-control" onChange={handleInput} />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Adduser;