import Header from './Component/Header';
import Home from './Component/Home';
import UserList from './Component/UserList';
import Adduser from './Component/Adduser';
import Edituser from './Component/Edituser';
import { Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="App">
        <Header />      
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/users" element={<UserList />} /> 
          <Route path="/adduser" element={<Adduser />} /> 
          <Route path="/edituser/:id" element={<Edituser />} /> 
        </Routes>
              
    </div>
  );
}

export default App;
