import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
function UserList() {
  const [userData, serUserData] = useState([]);
  const [message, setMessage] = useState('');
  useEffect(() => {    
    getUserData();
  }, []);

  const getUserData = async() => {
    const reqData = await fetch("https://usercrud.imageum.in/api/user.php");
    const resData = await reqData.json();
    console.log(resData);
    serUserData(resData);
  };

  const handleDelete = async(id)=>{
    const res = await axios.delete("https://usercrud.imageum.in/api/user.php/"+id);
    setMessage(res.data.success);
    getUserData();
  }

  return (
    <React.Fragment>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <h3>UserList</h3>
            <p className="text-danger">{message}</p>
            <table className="table table-striped table-bordered mt-3">
              <thead>
                <tr>
                  <th scope="col">Sr no.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Updated on</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                    userData.map((uData, index)=>(
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{uData.username}</td>
                            <td>{uData.email}</td>
                            <td>{uData.updated}</td>
                            <td>
                                <Link to={"/edituser/"+uData.id} className="btn btn-success mx-2">Edit</Link>
                                <button onClick={()=>handleDelete(uData.id)} className="btn btn-danger">Delete</button>
                            </td>
                        </tr>
                    ))
                }
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserList;
