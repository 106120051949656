import React from "react";

function Home() {
    return(
        <React.Fragment>
           <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Home</h1>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Home;